.Button {
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  font: inherit;
  text-align: center;
  padding: 10px 10px;
  margin: 10px;
  font-weight: bold;
  border-radius: 15px;
}

.Button:first-of-type {
  margin-left: 0;
  padding-left: 5;
}

.Button:disabled {
  background-color: rgb(141, 165, 141);
  color: #ccc;
  cursor: not-allowed;
}

.Send {
  background-color: green;
  color: white;
}

.Danger {
  background-color: #944317;
  color: white;
}
