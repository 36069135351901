.Revenue {
  margin: 5px auto;
  width: 80%;
  text-align: center;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  padding: 5px;
  box-sizing: border-box;
}

.Revenue h2, h3 {
  margin: 5px auto;
}

@media (min-width: 600px) {
  .Revenue {
    width: 500px;
  }
}
