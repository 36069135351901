.Input {
  width: 100;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
}

.Label {
  font-weight: bold;
  display: block;
  padding: 6px;
  width: 40%;
  text-align: end;
  /*   margin-bottom: 8px; */
}

.InputElement {
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 60%;
  box-sizing: border-box;
}

.InputElementNoLabel {
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.InputElement:focus, .InputElementNoLabel:focus {
  outline: none;
  background-color: #b9e8c7;
}

.InputElement:disabled {
  outline: none;
  background-color: #ccc;
}

.red {
  border: 2px solid #f40000;
  background-color: #ffbbbb;
}
